import React, { Component } from 'react';
import { ethers } from "ethers";
import bgImage from "./bgimage.jpg";
import skullapesTitleLogo from "./skullapeswordv2.png";
import './app.css';

const apevatar721V1ABI = require("./apevatar721V1ABI.json");
const basecoinV1ABI = require("./basecoinV1ABI.json");
const base1155V1ABI = require("./base1155V1ABI.json");

const chainIdToName = {
  1: "ETH Mainnet",
  4: "Rinkeby Testnet",
  10: "Optimism",
  56: "BNB Chain",
  137: "Polygon",
  1666600000: "Harmony Mainnet",
  1666700000: "Harmony Testnet",
  1666900000: "Harmony Devnet"
};

const avatarAddress = {
  1: '0x50862B4b2b74288dB62cCe75Adb25D5159BeB776',
  4: '0x507aE21DAcd09fF0fd8E43246b7B69dbC605Ba40',
  1666600000: '0x6e12A941FDe66acb9f31C02AcC21E9354F039FCA',
};

const erc20Address = {
  1: "0x5487e94ABaBDb05599C0E8317aCD4C85Bc4308f9",
  4: "0x8Cfe1a9C9B16aCda3ED49c1F49B16F204F8f95Be",
  1666600000: "0xD414b9A7B60a8224F602B7eCC96DeED77d8676b4",
}

const erc1155Address = {
  1: "0x52658199B7391eF429FbbFAaf20FD026ac224c0C",
  4: "0xb2b4b96EfeD4eD5beA20B4E6c40CAE19263a94ea",
  1666600000: "0x93b0521aA2643986b5197641DF08377f9fC0685a",
}

class Vault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ethAddress: null,
      chainId: null,
      avatarTokensOfOwner: null,
      avatar721name: null,
      balanceErc20: null,
      contractErc20Symbol: null,
    }
    this.provider = null;
  }

  getWallet = async() => {
    if (window.ethereum){
      await window.ethereum.request({ method: "eth_requestAccounts" });
      this.provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      const network = await this.provider.getNetwork();
      const chainId = network.chainId;
      const signer = this.provider.getSigner();
      const address = await signer.getAddress();
      this.setState({
        ethAddress: address,
        chainId: chainId,
      });
    }
  }

  getInventory = async(ethAddress, chainId) => {
    const avatarContract721 = new ethers.Contract(
      avatarAddress[chainId],
      apevatar721V1ABI,
      this.provider
      );
    
    const avatar721name = await avatarContract721.name();
    console.log("avatar721name", avatar721name);
    const avatarTokensOfOwner = await avatarContract721.tokensOfOwner(ethAddress);
    console.log("avatarTokensOfOwner", avatarTokensOfOwner);

    const contractErc20 = new ethers.Contract(
      erc20Address[chainId], 
      basecoinV1ABI, 
      this.provider
    );
    const contractErc20Symbol = await contractErc20.symbol();
    const balanceErc20 = await contractErc20.balanceOf(ethAddress);
    console.log("balanceErc20", ethers.utils.formatEther(balanceErc20));
    console.log("contractErc20Symbol", contractErc20Symbol);

    this.setState({
      avatarTokensOfOwner: avatarTokensOfOwner,
      avatar721name: avatar721name,
      balanceErc20: ethers.utils.formatEther(balanceErc20),
      contractErc20Symbol: contractErc20Symbol
    });
  
  }

  componentDidMount(){
    document.title = "Skullape Vault"
  }

  render() {
    const { ethAddress, chainId, avatar721name, avatarTokensOfOwner, balanceErc20, contractErc20Symbol } = this.state;
    return (
      <div>
        <video 
          //src={backgroundVideo} 
          autoPlay loop muted playsInline poster={bgImage}></video>
        <center>
        <img className="centerTitle"
                src={skullapesTitleLogo}
                width="320px"
                alt="skullape title"
                />
          <h3>Vault</h3>
          { window.ethereum && (!ethAddress) && 
          <button
            onClick={()=>{
              this.getWallet();
            }}
            >Connect Wallet</button> }
          
          { ethAddress && <div>{ethAddress} ({chainIdToName[chainId]})</div>}

          <br/>

          { ethAddress && !avatar721name &&
          <button
            onClick={()=>{
              this.getInventory(ethAddress, chainId);
            }}
            >Check Vault</button> }
          
          <br/>
          
          { avatar721name && <div><p>You own:</p>{avatarTokensOfOwner.length}x {avatar721name} <br/>{balanceErc20} {contractErc20Symbol}<br/><br/>({chainIdToName[chainId]})</div>}
          
          
        </center>
      </div>
    )
  }
}

export default Vault
