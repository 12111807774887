import React, { Component } from 'react'
import backgroundVideo from "./desertbg.mp4";
import Vault from "./Vault";
import skullapesmap from "./skullapesmap.jpg"
import skullapes from "./skullapeswordv2.png"
import bgImage from "./bgimage.jpg"
import './app.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagelink: ""
    }
  }

  componentDidMount(){
    document.title = "Skullapes: battles in a desert"; // update page title
    const url_qs = new URLSearchParams(window.location.search);
    if (url_qs.has("vault")) {
      this.setState({
        pagelink: "vault"
      });
    }
  }
  render() {
    const { pagelink } = this.state;
    if (pagelink === "vault") {
      return (
        <div>
          <Vault />
        </div>
      );
    } else
    return (
      <div>
        <video 
          //src={backgroundVideo} 
          autoPlay loop muted playsInline poster={bgImage}></video>
        <center>
          <img className="centerTitle"
                src={skullapes}
                width="1024"
                alt="skullape title"
                />
          <div className="container">
            <h3>
              An WebXR NFT game about apes battling in the desert, join the battle from your web browser.
            </h3>
          </div>
          <div className="titleMargin"></div>
          <div style={{position: "relative"}}>
            <img src={skullapesmap} style={{width: "100%", height: "auto"}} alt="skullape map"/>
              <a href="https://www.bluemetaverse.one/xtDVdME/skullapes-level-2"><div style={{position: "absolute", left: "38.5%", top: "74%", width: "16%", height: "4%", backgroundColor: "rgba(0, 0, 0, 0)"}}></div></a>
              <a href="https://www.bluemetaverse.one/xtDVdME/skullapes-level-2"><div style={{position: "absolute", left: "43%", top: "68%", width: "7%", height: "7%", backgroundColor: "rgba(0, 0, 0, 0)"}}></div></a>
              <a href="https://www.bluemetaverse.one/o7zNurV/one-desert"><div style={{position: "absolute", left: "45.8%", top: "64.2%", width: "22%", height: "4%", backgroundColor: "rgba(0, 0, 0, 0)"}}></div></a>
              <a href="https://www.bluemetaverse.one/o7zNurV/one-desert"><div style={{position: "absolute", left: "67%", top: "65%", width: "7%", height: "4%", backgroundColor: "rgba(0, 0, 0, 0)"}}></div></a>
              <a href="https://www.bluemetaverse.one/o7zNurV/one-desert"><div style={{position: "absolute", left: "72%", top: "67.7%", width: "5%", height: "3%", backgroundColor: "rgba(0, 0, 0, 0)"}}></div></a>
              <a href="https://www.bluemetaverse.one/o7zNurV/one-desert"><div style={{position: "absolute", left: "54.5%", top: "72%", width: "9%", height: "7.5%", backgroundColor: "rgba(0, 0, 0, 0)"}}></div></a>
              <a href="https://www.bluemetaverse.one/bjBgn3K/bayc"><div style={{position: "absolute", left: "7%", top: "93%", width: "2%", height: "3%", backgroundColor: "rgba(0, 0, 0, 0)"}}></div></a>
              <a href="https://www.bluemetaverse.one/bjBgn3K/bayc"><div style={{position: "absolute", left: "5.5%", top: "85.5%", width: "5%", height: "7.5%", backgroundColor: "rgba(0, 0, 0, 0)"}}></div></a>
              <a href="https://www.bluemetaverse.one/5cHTdYL/punk-city"><div style={{position: "absolute", left: "77.7%", top: "16.5%", width: "2%", height: "3%", backgroundColor: "rgba(0, 0, 0, 0)"}}></div></a>
              <a href="https://www.bluemetaverse.one/5cHTdYL/punk-city"><div style={{position: "absolute", left: "77.4%", top: "10.5%", width: "3%", height: "6%", backgroundColor: "rgba(0, 0, 0, 0)"}}></div></a>
              <a href="https://www.bluemetaverse.one/vDdA6wJ/dragon-chamber"><div style={{position: "absolute", left: "20%", top: "70%", width: "8%", height: "9%", backgroundColor: "rgba(0, 0, 0, 0)"}}></div></a>
              <a href="https://www.bluemetaverse.one/vDdA6wJ/dragon-chamber"><div style={{position: "absolute", left: "15%", top: "78%", width: "18%", height: "4%", backgroundColor: "rgba(0, 0, 0, 0)"}}></div></a>
              
          </div>
          <h1>
            The Hunt Awaits
          </h1>
          <p>
            For our launch on August we'll be launching with a desert wide treasure hunt in the metaverse. 
          </p>
          <div className="margin-bottem"></div>
            <h1>
              Battle Rules
            </h1>
          <div className="container">
            <p>
              Skullapes is an fps/scavenger hunt game. Each battle is about a 5-min duration to determine a winner. Players would go around a map (ex. desert) collecting bananas and ape skulls for points. Whilst shooting each other with bananas, and axes. Game winners are awarded with tokens as experience points and NFTs that unlock wearables, special abilities (like flying), and access to boss fights. 
            </p>
          </div>
          <div className="margin-bottem"></div>
          <h2>
            Preparation for the hunt
          </h2>
          <p>
            Follow our {" "}
            <a style={{color: "white"}} href="https://discord.gg/dSHYRtHUhm"> twitter</a>
            {" "} for updates!
          </p>
          <a href="https://twitter.com/skullapes">
          <img alt="skullape twitter" src="https://www.computerhope.com/jargon/t/twitter.png"
          width="100"></img>
          </a>
          <p>
            Join our {" "}
            <a style={{color: "white"}} href="https://discord.gg/dSHYRtHUhm"> discord</a>
            {" "} to join play test!
          </p>
          <a href="https://discord.gg/dSHYRtHUhm">
          <img alt="skullape discord" src="https://pnggrid.com/wp-content/uploads/2021/05/Discord-Logo-Circle-1024x1024.png"
          width="100"></img>
          </a>
          <p>
            Check out our <a style={{color: "white"}} href="https://dappradar.com/harmony/games/skullapes"> DappRadar</a>
          </p>
          <a href="https://dappradar.com/harmony/games/skullapes"><img alt="skullape DappRadar" src="https://avatars.githubusercontent.com/u/48711064?s=280&amp;v=4" width="100"/></a>
          <div className="margin-bottem"></div>
          <p>2022</p>
        </center>
      </div>
    )
  }
}

export default App
